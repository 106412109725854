require("./scss/banner.scss");
require("slick-carousel/slick/slick.css");
require("slick-carousel/slick/slick-theme.css");
require("slick-carousel");
// Slider principal single
$('.slider').slick({
    fade: true,
    // cssEase: 'linear',
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 6000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows:true,
  });


  jQuery(function($) {
    var $s = $('.my-slider-two').slick({
          cssEase: 'linear',
          speed: 8000,
          autoPlay: false,
          pauseOnHover: false,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
              }
            } ]
      });
      $('.gotoright').click(function($) {
        $s.slick("setOption","speed",1,false)
      });
    $('.gotoleft').click(function($) {
      });
    });

